const MuiToolbar = {
  styleOverrides: {
    root: ({ theme }) => ({
      minHeight: 0,
      [theme.breakpoints.up('xs')]: {
        minHeight: 0,
      },
    }),
  },
};

export default MuiToolbar;
