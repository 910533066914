const palette = {
  primary: {
    main: '#E62E66',
    light: '#fdeaf0',
    unreadNotification: '#FDEEF3',
    dark: '#a12047',
    mild: '#eb5784',
  },
  secondary: {
    main: '#6F178A',
  },
  kobros: {
    main: '#7205A3',
    dark: '#4E0D6C',
    contrastText: '#F7F7F7',
    unreadNotification: '#FDEEF3',
    mild: '#A05FCC',
    light: '#E5D9F2',
  },
  fingoWhite: {
    main: '#FFF',
  },
  text: {
    main: '#323232',
    primary: '#323232',
    secondary: '#323232',
    light: '#666666',
    contrastText: '#F7F7F7',
  },
  gray: {
    main: '#7D7D7D',
    light: '#7D7D7D10',
    dark: '#c4c4c4',
  },
  chart: {
    primary: '#E62E66',
    secondary: '#999999',
    lightPinkColor: '#e62e6629',
  },
  error: { main: '#B30100', secondary: '#f44336', dark: '#D13F34' },
  read: { main: '#53BDEB' },
  warning: { main: '#ff9800', light: '#FFCF40' },
  success: { main: '#2A7A23' },
  excel: { main: '#1D6F42', contrastText: '#F7F7F7' },
  action: { disabled: '#ABABAB' },
  lokal: {
    main: '#00594C',
    dark: '#003d34',
    contrastText: '#F7F7F7',
  },
  background: {
    dark: '#F0F0F0',
    light: '#F7F7F7',
  },
};

export default palette;
