const MuiButton = {
  variants: [
    {
      props: { variant: 'containedReverse', color: 'primary' },
      style: ({ theme }) => ({
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.background.light,
        },
      }),
    },
    {
      props: { variant: 'form' },
      style: ({ theme }) => ({
        color: 'white',
        borderRadius: 30,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&:disabled': {
          backgroundColor: '#c4c4c4',
        },
      }),
    },
    {
      props: { variant: 'white' },
      style: ({ theme }) => ({
        backgroundColor: 'white',
        color: 'black',
        '&:hover': {
          backgroundColor: theme.palette.background.light,
        },
      }),
    },
    {
      props: { variant: 'link' },
      style: ({ theme }) => ({
        color: theme.palette.primary.main,
        ...theme.typography.body2,
        '&:hover': {
          backgroundColor: 'transparent',
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      }),
    },
    {
      props: { variant: 'underlineLink' },
      style: ({ theme }) => ({
        color: theme.palette.primary.main,
        ...theme.typography.body2,
        textDecoration: 'underline',
        '&:hover': {
          backgroundColor: 'transparent',
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      }),
    },
    {
      props: { variant: 'hoverHighlightButton' },
      style: ({ theme }) => ({
        padding: 2,
        width: '50%',
        height: 80,
        backgroundColor: '#F2F2F2',
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: '#FFFFFF',
        },
        fontSize: { xs: 12, md: 15 },
      }),
    },
    {
      props: { variant: 'paper' },
      style: {
        borderRadius: '4px',
        boxShadow:
          'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
      },
    },
    {
      props: { variant: 'dropDown' },
      style: ({ theme }) => ({
        justifyContent: 'flex-start',
        color: theme.palette.text.main,
        fontSize: 12,
        padding: 0,
        '& .MuiButton-startIcon': {
          color: theme.palette.primary.main,
          fontWeigth: 'bold',
          fontSize: 13,
        },
        '& .MuiButton-endIcon': {
          color: theme.palette.primary.main,
        },
      }),
    },
  ],
  styleOverrides: {
    root: {
      padding: '0 14px',
      borderRadius: 8,
    },
    label: {
      height: '100%',
    },
    sizeSmall: {
      padding: '8px 16px',
      height: 'min-content',
    },
  },
};

export default MuiButton;
