const MuiTableCell = {
  styleOverrides: {
    root: {
      padding: '0.5rem 0.3rem',
      fontSize: 12,
      lineHeight: '0.8rem !important',
      verticalAlign: 'middle',
      border: 'none !important',
      borderStyle: 'none !important',
      borderBottom: 'none !important',
      backgroundColor: 'transparent',
      '&:first-of-type': {
        borderLeftStyle: 'none',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
      },
      '&:last-child': {
        borderRightStyle: 'none',
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10,
      },
    },
    head: {
      verticalAlign: 'middle',
      fontWeight: 'normal',
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'white',
      '&:first-of-type': {
        borderLeftStyle: 'none',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        boxShadow: 'none',
      },
      '&:last-child': {
        borderRightStyle: 'none',
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10,
        boxShadow: 'none',
      },
    },
    sizeSmall: {
      padding: '0.5rem 0.3rem !important',
      '&:first-of-type': {
        paddingLeft: '0.5rem !important',
      },
      '&:last-child': {
        paddingRight: '0.5rem !important',
      },
    },
  },
};

export default MuiTableCell;
