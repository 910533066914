const MuiTableContainer = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      overflowX: 'unset',
    },
  },
};

export default MuiTableContainer;
