import MuiButton from './MuiButton';
import MuiCssBaseline from './MuiCssBaseline';
import MuiDataGrid from './MuiDataGrid';
import MuiFilledInput from './MuiFilledInput';
import MuiFormLabel from './MuiFormLabel';
import MuiLink from './MuiLink';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaginationItem from './MuiPaginationItem';
import MuiSelect from './MuiSelect';
import MuiStepper from './MuiStepper';
import MuiTab from './MuiTab';
import MuiTable from './MuiTable';
import MuiTableCell from './MuiTableCell';
import MuiTableContainer from './MuiTableContainer';
import MuiTablePagination from './MuiTablePagination';
import MuiTableRow from './MuiTableRow';
import MuiTextField from './MuiTextField';
import MuiToolbar from './MuiToolbar';
import MuiTooltip from './MuiTooltip';

const components = {
  MuiButton,
  MuiCssBaseline,
  MuiDataGrid,
  MuiFilledInput,
  MuiFormLabel,
  MuiLink,
  MuiOutlinedInput,
  MuiPaginationItem,
  MuiSelect,
  MuiStepper,
  MuiTab,
  MuiTable,
  MuiTableCell,
  MuiTableContainer,
  MuiTablePagination,
  MuiTableRow,
  MuiTextField,
  MuiToolbar,
  MuiTooltip,
};

export default components;
