import PropTypes from 'prop-types';
import { CountryType } from './CountryType';
import { CommissionRuleType } from './CommissionRuleType';
import { MoneyType } from './MoneyType';
import { ModuleGroupType } from './ModuleGroupType';

const CompanyCreditLineType = PropTypes.shape({
  id: PropTypes.string,
  totalAmount: MoneyType,
  creditUsed: MoneyType,
  approvedOverdraft: MoneyType,
  availableLine: PropTypes.number,
});

const MasterEntityType = PropTypes.shape({
  id: PropTypes.string,
  rut: PropTypes.string,
  name: PropTypes.string,
  displayNationalIdentifier: PropTypes.string,
  country: CountryType,
});

const CompanyType = PropTypes.shape({
  id: PropTypes.string,
  rut: PropTypes.string,
  name: PropTypes.string,
  phoneNumber: PropTypes.string,
  hasBankAccount: PropTypes.bool,
  isAcepta: PropTypes.bool,
  masterEntity: MasterEntityType,
  // eslint-disable-next-line react/forbid-prop-types
  bankAccounts: PropTypes.arrayOf(PropTypes.object),
  currentCommissionRules: PropTypes.arrayOf(CommissionRuleType),
  currentCreditLine: CompanyCreditLineType,
  integrationMethod: PropTypes.string,
  hasSiiCredentials: PropTypes.bool,
  hasInvoiceProviderCredentials: PropTypes.bool,
  possibleInvoiceProvider: PropTypes.string,
  hasSupplierPortalCredentials: PropTypes.bool,
  hasLendingAcceptedterms: PropTypes.bool,
  hasDigitalCertificate: PropTypes.bool,
  hasCertificateCredentials: PropTypes.bool,
  pendingReintegrations: PropTypes.shape({
    count: PropTypes.number,
  }),
  currentDigitalCertificate: PropTypes.string,
  hasOrderingDebt: PropTypes.bool,
  hasFrameworkContract: PropTypes.bool,
  hasMissingCredentials: PropTypes.number,
  currentSurplusBalanceValue: MoneyType,
  negativeSurplusBalance: MoneyType,
  showReferralCampaign: PropTypes.bool,
  isSenegocia: PropTypes.bool,
  isOrdering: PropTypes.bool,
  groupModules: PropTypes.arrayOf(ModuleGroupType),
  currentCompanyConfirmingCreditLine: PropTypes.shape({
    id: PropTypes.string,
    limit: PropTypes.number,
  }),
  executiveAssigned: PropTypes.string,
});

export { MasterEntityType, CompanyType, CompanyCreditLineType };
