const MuiPaginationItem = {
  styleOverrides: {
    sizeSmall: ({ theme }) => ({
      fontSize: 14,
      fontWeight: '500',
      color: theme.palette.text.primary,
    }),
  },
};

export default MuiPaginationItem;
