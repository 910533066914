import PropTypes from 'prop-types';
import moment from 'moment';
import { MoneyType } from './MoneyType';
import { CompanyType } from './CustomersType';

const ConfirmingOperationType = PropTypes.shape({
  id: PropTypes.string,
  contactEmail: PropTypes.string,
  expirationDate: PropTypes.instanceOf(moment),
  bankAccount: PropTypes.shape({
    id: PropTypes.string,
    bankName: PropTypes.string,
    accountNumber: PropTypes.string,
  }),
  confirmingSet: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      company: CompanyType,
      folio: PropTypes.string,
      dateIssued: PropTypes.string,
      dateExpiration: PropTypes.string,
      amountWithIva: MoneyType,
    }),
  ),
});

const ConfirmingPayrollType = PropTypes.shape({
  id: PropTypes.string,
  createdAt: PropTypes.instanceOf(moment),
  operations: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.shape({
      node: ConfirmingOperationType,
    })),
  }),
});

export { ConfirmingPayrollType, ConfirmingOperationType };
