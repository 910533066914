const MuiTab = {
  styleOverrides: {
    root: {
      '&$selected': {
        fontWeight: 'bold',
      },
    },
  },
};

export default MuiTab;
