const MuiTablePagination = {
  styleOverrides: {
    root: {
      marginTop: '1rem',
    },
    toolbar: {
      justifyContent: 'center',
    },
    spacer: {
      flex: 0,
    },
  },
};

export default MuiTablePagination;
