import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { esES as coreES } from '@mui/material/locale';
import { esES } from '@mui/x-data-grid';
import breakpoints from './breakpoints';
import palette from './palette';
import typography from './typography';
import components from './components';

const theme = createTheme(
  {
    palette,
    breakpoints,
    typography,
    components,
  },
  coreES,
  esES,
);

theme.typography.body2 = {
  ...theme.typography.body2,
  [theme.breakpoints.down('md')]: {
    fontSize: 10,
  },
};

export default responsiveFontSizes(theme);
