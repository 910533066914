const MuiCssBaseline = {
  styleOverrides: (theme) => ({
    body: {
      margin: 0,
      height: '100%',
      '*::-webkit-scrollbar': {
        width: 18,
      },
      '*::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.gray.dark,
        borderRadius: 20,
        border: '6px solid transparent',
        backgroundClip: 'content-box',
        '&:hover': {
          backgroundColor: '#CCCCCC',
        },
      },
    },
    '#root': {
      height: '100%',
    },
  }),
};

export default MuiCssBaseline;
