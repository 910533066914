const MuiTooltip = {
  styleOverrides: {
    tooltip: {
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 300,
    },
  },
};

export default MuiTooltip;
