/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

const EvaluationCommentPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  comments: PropTypes.string.isRequired,
});

export default EvaluationCommentPropType;
