import PropTypes from 'prop-types';

const CurrencyType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,

});

const MoneyType = PropTypes.shape({
  amount: PropTypes.number,
  currency: CurrencyType,
});

export { MoneyType, CurrencyType };
