/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

const ModuleGroupType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export { ModuleGroupType };
