import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CREDIT_LINE_MOVEMENTS_OF_OBJECT } from '../../graphql';
import FingoMainView from '../../components/layout/FingoMainView';
import { FingoDataGrid } from '../../components/dataGrids';
import creditLineMovementColumns from '../../constants/creditLineMovementColumns';

const ObjectCreditLineMovements = ({ globalAppId }) => {
  const columns = creditLineMovementColumns;
  const includeHeaders = ['id', 'movementType', 'amount', 'creditLine_MasterEntity_Name'];
  return (
    <Box sx={{ height: 350 }}>
      <FingoMainView
        id="client-factoring-datagrid"
        query={CREDIT_LINE_MOVEMENTS_OF_OBJECT}
        queryCustomVariables={{
          globalAppId,
        }}
        hideReloadButton
        slots={{
          table: FingoDataGrid,
        }}
        slotProps={{
          table: {
            columns,
            includeHeaders,
            noRowsMessage: () => <Typography>Sin movimientos</Typography>,
            rowsPerPageOptions: [25, 50, 100],
            initialOrderBy: 'id',
          },
        }}
      />
    </Box>
  );
};

ObjectCreditLineMovements.propTypes = {
  globalAppId: PropTypes.string.isRequired,
};

export default ObjectCreditLineMovements;
