const MuiTextField = {
  defaultProps: {
    InputLabelProps: {
      shrink: true,
    },
  },
  styleOverrides: {
    root: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '30px',
      },
      '& .MuiInputLabel-root': {
        opacity: 1,
      },
      '& .MuiFormHelperText-root': {
        minHeight: 20,
        fontSize: 10,
      },
    },
  },
};

export default MuiTextField;
