/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

const CommissionRuleType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  threshold: PropTypes.number.isRequired,
  variant: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

export { CommissionRuleType };
