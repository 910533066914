const MuiTable = {
  styleOverrides: {
    root: {
      borderCollapse: 'separate',
      borderSpacing: '0 10px',
      marginTop: -10,
    },
  },
};

export default MuiTable;
