const MuiOutlinedInput = {
  styleOverrides: {
    input: {
      padding: '8px 14px',
    },
    root: {
      minHeight: 30,
    },
  },
  variants: [
    {
      props: { variant: 'form' },
      style: ({ theme }) => ({
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '15px',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }),
    },
  ],
};

export default MuiOutlinedInput;
