/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

const EnumOptionsType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  }),
);

export { EnumOptionsType };
