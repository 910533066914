/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

const DocumentFinanceStateType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  estimatedPaymentDate: PropTypes.string,
  paymentDate: PropTypes.string,
  comments: PropTypes.string,
  isAutomatic: PropTypes.bool,
  eventTime: PropTypes.string,
  status: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    status: PropTypes.string,
  }),
  rejectionCode: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    reason: PropTypes.string,
  }),
  bank: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
});

export { DocumentFinanceStateType };
