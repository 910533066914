/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import { IP_POOL_TYPES } from '../constants/ip-pool-types';

const IdValueType = PropTypes.shape({
  id: PropTypes.string,
  value: PropTypes.string,
});

const MailTemplateType = PropTypes.shape({
  id: PropTypes.string,
  version: PropTypes.string,
  template: PropTypes.string,
});

const UserMailType = PropTypes.shape({
  id: PropTypes.string,
  email: PropTypes.string,
});

const MailParamType = PropTypes.shape({
  id: PropTypes.string,
  requestingPlatform: IdValueType,
  country: IdValueType,
  template: MailTemplateType,
  bcc: PropTypes.arrayOf(UserMailType),
  cc: PropTypes.arrayOf(UserMailType),
  to: PropTypes.arrayOf(UserMailType),
  from: UserMailType,
});

const MailType = PropTypes.shape({
  id: PropTypes.string,
  emailName: PropTypes.string,
  asm: PropTypes.string,
  ipPoolName: PropTypes.string,
  mailparams: PropTypes.arrayOf(MailParamType),
});

const MailBaseType = PropTypes.shape({
  id: PropTypes.string,
  emailName: PropTypes.string,
  asm: PropTypes.string,
  ipPoolName: PropTypes.string,
});

const SelectedParamsEmptyValues = {
  requesting_platform: null,
  fromMail: null,
};

const MailTemplateEmptyValues = {
  id: null,
  version: null,
  template: null,
};

const MailTemplateBlackList = {
  id: null,
  version: 'BLACKLIST',
  template: null,
};

const MailParamEmptyValues = {
  id: null,
  requestingPlatform: {
    id: null,
    value: null,
  },
  country: {
    id: null,
    value: null,
  },
  template: {
    id: null,
    version: null,
    template: null,
  },
  bcc: [],
  cc: [],
  to: [],
  from: {
    id: null,
    email: null,
  },
};

const MailEmptyValues = {
  id: null,
  emailName: null,
  asm: null,
  ipPoolName: null,
  mailparams: MailParamEmptyValues,
};

const formatSelectedMailParam = (param) => ({
  id: param?.id,
  requestingPlatform: {
    id: param.requestingPlatform?.id,
    value: param.requestingPlatform?.code,
  },
  country: {
    id: param.country?.id,
    value: param.country?.name,
  },
  template: {
    id: param.mailTemplate?.id,
    version: param.mailTemplate?.version,
    template: param.mailTemplate?.template,
  },
  bcc: param.bcc.map((bcc) => ({
    id: bcc?.id,
    email: bcc?.email,
  })),
  cc: param.cc.map((cc) => ({
    id: cc?.id,
    email: cc?.email,
  })),
  to: param.to.map((to) => ({
    id: to?.id,
    email: to?.email,
  })),
  from: {
    id: param.fromEmail?.id,
    email: param.fromEmail?.email,
  },
});

const formatSelectedMail = (mail) => ({
  graphqlId: mail.graphqlId,
  id: mail.id,
  emailName: mail.emailName,
  asm: mail.asm?.id,
  ipPoolName: IP_POOL_TYPES[mail.ipPoolName],
  mailParams: mail.mailparametersSet?.map((param) => formatSelectedMailParam(param)) || [],
});

export {
  MailType,
  MailBaseType,
  MailParamType,
  MailTemplateType,
  MailTemplateEmptyValues,
  MailTemplateBlackList,
  MailEmptyValues,
  MailParamEmptyValues,
  SelectedParamsEmptyValues,
  UserMailType,
  formatSelectedMail,
  formatSelectedMailParam,
};
