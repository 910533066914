const MuiSelect = {
  defaultProps: {
    inputProps: {
      'data-hj-allow': '',
    },
    sx: {
      '& .MuiInput-underline:before': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:after': {
        borderBottom: 'none',
      },
    },
  },
};

export default MuiSelect;
