/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import { FilePropType } from './FilePropType';

export const InvoiceDocumentPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  globalAppId: PropTypes.string.isRequired,
  file: FilePropType.isRequired,
  type: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shortName: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }),
});
