const MuiFilledInput = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 8,
      backgroundColor: theme.palette.background.dark,
      '&:before': {
        border: 0,
      },
      '&:hover:before': {
        border: 0,
      },
      height: 45,
    }),
  },
};

export default MuiFilledInput;
