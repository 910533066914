import PropTypes from 'prop-types';
import { AuthenticationTypeType } from './AuthenticationTypeType';

const CredentialProviderTypeType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

const IntegratorCredentialProviderType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  integrated: PropTypes.bool.isRequired,
  iconUrl: PropTypes.string,
  credentialProviderType: CredentialProviderTypeType.isRequired,
});

const CredentialType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  secondUsername: PropTypes.string,
  certificate: PropTypes.string.isRequired,
  authenticationType: AuthenticationTypeType.isRequired,
  credentialProvider: IntegratorCredentialProviderType.isRequired,
});

export { CredentialProviderTypeType, IntegratorCredentialProviderType, CredentialType };
